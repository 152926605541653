import React from 'react';
import Helmet from 'react-helmet';

import Layout from '../components/layout';

import capa from '../images/letreiro.jpg';

const AboutPage = () => (
	<Layout>
		<Helmet>
			<title>Goods Br - Sobre</title>
			<meta property="og:title" content="Goods Br - Sobre" />
		</Helmet>
		<div
			className="info-banner"
			style={{
				backgroundImage: `linear-gradient(180deg, hsla(0, 0%, 7%, .8), hsla(0, 0%, 7%, .8)), url("${capa}")`
			}}
		/>
		<div className="info-content">
			<div className="container">
				<h1 className="info-title">Quem somos</h1>
				<p className="info-p">
					<strong>Mateus Secco Neumann</strong>, arquiteto e fundador da marca, é apaixonado por arquitetura
					contemporânea. Em uma viagem de imersão conheceu 15 países da América que lhe abasteceu de muitas
					referências. Aprofundou seus conhecimentos sobre diferentes linguagens decorativas. Mateus é
					conhecido por desenvolver os estandes mais originais das feiras de decoração. Diretor do
					departamento de desenvolvimento de produtos da empresa, declara: <strong>Goods Br</strong> será
					reconhecida internacionalmente.
				</p>
				<p className="info-p">
					Movido pelo espírito cooperativo e pelo trabalho em equipe desde os tempos de escotismo, no qual foi
					o fundador do Grupo de Gramado aos 11 anos, <strong>Josué Neumann Spengler</strong> começou a
					carreira profissional cedo, aos 17 anos, em empresas da área de TI. Com a vocação para juntar
					pessoas ao redor de um objetivo, obteve resultados expressivos na área trabalhando em empresas em
					Porto Alegre e Nova Petrópolis. Em 2007, encarou o desafio de planejar todo o sistema de importação
					da <strong>Goods Br</strong>, da busca de fornecedores no exterior ao planejamento e distribuição no
					território nacional. Com 11 anos de empresa, desde a sua fundação, Josué transforma clientes,
					colaboradores e fornecedores em uma grande rede de cooperação em prol do desenvolvimento de todos.
				</p>
				<img
					src="https://www.goodsbr.com.br/production/assets/about/aerea-419c567879a66ea41864e115fff8c277.jpg"
					alt=""
					className="info-image"
				/>
				<div className="button-center">
					<a
						href="https://incorporadora.goodsbr.com.br/"
						target="_blank"
						rel="noopener noreferrer"
						className="button info w-button"
					>
						Visitar o site da Goods Br Home
					</a>
					<a
						href="https://www.goodsbr.com.br/catalogo/"
						target="_blank"
						rel="noopener noreferrer"
						className="button info w-button"
					>
						Comprar produtos Goods Br
					</a>
				</div>
			</div>
		</div>
	</Layout>
);

export default AboutPage;
